import { TableLayout, TableLayoutRow } from "@/types/BasicComponent";
import { formatDate, formatTime } from "@/utils/common";
import dayjs from "dayjs";
import * as math from "mathjs"

const getHeaderBaseStyle = (width: string) => {
  return {
    height: '40px', fontWeight: 'bold', border: '1px solid black', width
  }
}

const getBodyBaseStyle = (textAlign?: string) => {
  const result: AnyObject = {
    border: '1px solid black',
    height: '35px',
  }
  if (textAlign) result.textAlign = textAlign
  return result
}

export const getWarehouseInExportLayout: (orderInfo: AnyObject, wiInfo: AnyObject, xwrdList: AnyArray) => TableLayout = (orderInfo: AnyObject, wiInfo: AnyObject, xwrdList: AnyArray) => {

  let amountSum: math.MathType = 0

  const dataLayoutList: TableLayoutRow[] = xwrdList.map((xwrd, idx) => {
    amountSum = math.add(amountSum, xwrd.xwrdNum)
    return [
      { data: idx + 1, style: getBodyBaseStyle() },
      { data: xwrd.goodsName, style: getBodyBaseStyle() },
      { data: xwrd.xwrdUnit, style: getBodyBaseStyle() },
      { data: xwrd.xwrdNum, style: getBodyBaseStyle() },
      { data: dayjs(xwrd.xwrdDate).format('YYYYMMDD'), style: getBodyBaseStyle() },
      { data: '', style: getBodyBaseStyle() }
    ]
  })

  return {
    thead: [
      [{ colSpan: 6, data: `${wiInfo.corpName}-商品入库单`, style: { fontWeight: 'bold', fontSize: '20px' } }],
      [{ colSpan: 4, data: wiInfo.diAddress + (wiInfo.diAddress.endsWith('园区')? '': '园区') , style: { textAlign: 'left' } }, { colSpan: 2, data: `仓库单号:${wiInfo.xwrCode}`, style: { textAlign: 'left' } }],
      [{ colSpan: 4, data: `供应商:${wiInfo.supplierName}`, style: { textAlign: 'left' } }, { colSpan: 2, data: `入库日期:${formatDate(wiInfo.xwrTime)}`, style: { textAlign: 'left' } }],
      [{ colSpan: 4, data: `备 注:${orderInfo.remark || ''}`, style: { textAlign: 'left' } }, { colSpan: 2, data: `关联单:${wiInfo.relevanceCode}`, style: { textAlign: 'left' } }],
      [
        { data: '序号', style: getHeaderBaseStyle('60px') }, 
        { data: '货物名称', style: getHeaderBaseStyle('200px') }, 
        { data: '单位', style: getHeaderBaseStyle('100px') }, 
        { data: '入库数量', style: getHeaderBaseStyle('150px') }, 
        { data: '生产批号', style: getHeaderBaseStyle('300px') }, 
        { data: '备注', style: getHeaderBaseStyle('100px') },
      ]
    ],
    tbody: [
      ...dataLayoutList,
      [
        { data: '', style: getBodyBaseStyle() },
        { data: `合计：`, colSpan: 2, style: {border: '1px solid black', textAlign: 'right'} },
        { data: amountSum, colSpan: 1, style: {border: '1px solid black'} },
        { colSpan: 2, style: {border: '1px solid black'} }
      ],
    ],
    tfoot: [
      [ 
        { 
          render: () => 
            <span>
              仓管: {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
              采购员: {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
              审核: {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
              送货人:
            </span>, 
          colSpan: 6 
        } 
      ]
    ]
  }
}

export const getWarehouseOutExportLayout: (orderInfo: AnyObject, xwrInfo: AnyObject, xwrList: AnyArray) => TableLayout = (orderInfo: AnyObject, xwrInfo: AnyObject, xwrList: AnyArray) => {

  let amountSum: math.MathType = 0
  let orderSum: math.MathType = 0
  
  const dataLayoutList: TableLayoutRow[] = xwrList.map((xwrd, idx) => {
    amountSum = math.add(amountSum, xwrd.xwrdTotalPrice)
    orderSum = math.add(orderSum, 1)
    return [
        { data: idx + 1, style: getBodyBaseStyle() },
        { data: xwrd.goodsName, style: getBodyBaseStyle() },
        { data: 1, style: getBodyBaseStyle() },
        { data: xwrd.xwrdPrice, style: getBodyBaseStyle() },
        { data: xwrd.xwrdPrice, style: getBodyBaseStyle() },
        { data: xwrd.xwrdNum, style: getBodyBaseStyle() },
        { data: xwrd.xwrdTotalPrice, style: getBodyBaseStyle() },
        { data: dayjs(xwrd.xwrdDate).format('YYYYMMDD'), style: getBodyBaseStyle() },
        // { data: xwrd.volume, style: getBodyBaseStyle() },
        { data: '', style: getBodyBaseStyle() },
    ]
  })

  return {
    thead: [
      [{ colSpan: 10, data: `${xwrInfo.corpName} 出库单`, style: { fontWeight: 'bold', fontSize: '20px',height: '25px' } }],
      [{ colSpan: 10, style: {height: '25px' } }],
      [{ colSpan: 5, data: '出库部门：生产部', style: { textAlign: 'left', fontWeight: 'bold',height: '25px' } }, { colSpan: 4, data: `编号：${xwrInfo.xwrCode}`, style: { textAlign: 'right', fontWeight: 'bold' } }],
      [{ colSpan: 5, data: '备注：', style: { textAlign: 'left', fontWeight: 'bold',height: '25px' } }, { colSpan: 4, data: `送货日期：${formatDate(xwrInfo.sendDate)}`, style: { textAlign: 'right', fontWeight: 'bold' } }],
      [
        { data: '序号', style: getHeaderBaseStyle('60px') }, 
        { data: '货物名称', style: getHeaderBaseStyle('200px') }, 
        { data: '订单数', style: getHeaderBaseStyle('100px') }, 
        { data: '单价', style: getHeaderBaseStyle('100px') }, 
        { data: '折扣价', style: getHeaderBaseStyle('100px') }, 
        { data: '实发数', style: getHeaderBaseStyle('100px') },
        { data: '金额', style: getHeaderBaseStyle('130px') },
        { data: '生产批号', style: getHeaderBaseStyle('100px') },
        // { data: '实收数', style: getHeaderBaseStyle('100px') },
        { data: '备注', style: getHeaderBaseStyle('100px') },
      ]
    ],
    tbody: [
      ...dataLayoutList,
      [
        { data: '合计：', colSpan: 2, style: Object.assign(getBodyBaseStyle('right'), { fontWeight: 'bold' }) },
        { data: orderSum.toString(), style: getBodyBaseStyle('center') },
        { data: '', style: getBodyBaseStyle('center') },
        { data: '', style: getBodyBaseStyle('center') },
        { data: xwrInfo.totalNum, style: getBodyBaseStyle('center') },
        { data: xwrInfo.totalPrice, style: getBodyBaseStyle('center') },
        { data: '', style: getBodyBaseStyle('center') },
        // { data: ptInfo.total_num, style: getBodyBaseStyle('center') },
        { data: '', style: getBodyBaseStyle('center') },
      ],
    ],
    tfoot: [
      [{ data: '说明：', colSpan: 9, style: { fontWeight: 'bold', textAlign: 'left' } }],
      [{ data: '1.以上商品均已履行进货检查验收法定程序，索检票证齐全。', colSpan: 9, style: { fontWeight: 'bold', textAlign: 'left' } }],
      [{ data: '2.收货请及时查收，如有误差或质量问题，请在48小时内致电:         联系，逾期自负。', colSpan: 9, style: { fontWeight: 'bold', textAlign: 'left' } }],
      [{ data: `仓库：${xwrInfo.xqjzName}     财务：    配送：${xwrInfo.driverName || ''}    验收：    经手人：    `, colSpan: 9, style: { fontWeight: 'bold', textAlign: 'left' }}]
    ]
  }
}