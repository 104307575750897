import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-18c4a946"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_timeline = _resolveComponent("el-timeline")!
  const _component_XqjzPosMap = _resolveComponent("XqjzPosMap")!
  const _component_ExportTable = _resolveComponent("ExportTable")!
  const _component_BasicPage = _resolveComponent("BasicPage")!

  return (_openBlock(), _createBlock(_component_BasicPage, {
    borderRadius: "0px",
    margin: "0px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_timeline, { style: {"width":"1200px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  class: "description-item",
                  column: 3,
                  border: "",
                  title: "订单信息"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 订单号 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.orderInfo.orderCode), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 胶合板厂 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.orderInfo.jhbcName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 生产机主 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.orderInfo.xqjzName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, { span: "3" }, {
                      label: _withCtx(() => [
                        _createTextVNode(" 货物信息 ")
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_descriptions, {
                          class: "margin-top",
                          column: 5,
                          direction: "vertical"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderInfo.goodsList, (goods, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: goods.g_id
                              }, [
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物名称",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.categoryName) + " " + _toDisplayString(goods.goodsName), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物描述",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.unitDesc), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物单价(元)",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.unitPrice), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物数量",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.volume + '方'), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"]),
                                _createVNode(_component_el_descriptions_item, {
                                  label: "货物总价(元)",
                                  "label-class-name": index == 0? '': 'hidden'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(goods.totalPrice), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label-class-name"])
                              ], 64))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 订单总价(元) ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.orderInfo.totalprice), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createElementVNode("template", null, [
                  _createVNode(_component_el_empty, { description: "description" })
                ]),
                _createVNode(_component_el_descriptions, {
                  title: "原木入库",
                  class: "description-item",
                  direction: "vertical",
                  column: 6,
                  border: ""
                }, {
                  extra: _withCtx(() => [
                    (_ctx.woodIntoInfo.wiInfo)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "primary",
                          size: "small",
                          onClick: _ctx.onExportWarehouseIn
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("导出")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.woodIntoInfo.wiInfo)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.woodIntoInfo.xwrdList, (xbo, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: xbo.id
                            }, [
                              _createVNode(_component_el_descriptions_item, {
                                label: "产品名称",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(xbo.goodsName), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "采购数量",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(xbo.xwrdNum + xbo.xwrdUnit), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "采购时间",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.woodIntoInfo.wiInfo.xwrTime)), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "采购人员",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.woodIntoInfo.wiInfo.supplierName), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"])
                            ], 64))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_empty, {
                                  description: "暂无原木入库信息",
                                  "image-size": 60,
                                  style: {"height":"125px"}
                                })
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "生产机主信息",
                  class: "description-item",
                  column: 3,
                  border: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 旋切机主 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.xqjzInfo.name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 电话 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.xqjzInfo.phone), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 身份证号 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.xqjzInfo.idCard), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 机器编号 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.xqjzInfo.deviceCode), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 机器经纬度 ")
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          (_ctx.xqjzInfo.deviceLon && _ctx.xqjzInfo.deviceLat)
                            ? (_openBlock(), _createBlock(_component_el_row, {
                                key: 0,
                                justify: "space-between"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_space, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" 经度 "),
                                      _createVNode(_component_el_tag, { size: "small" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.xqjzInfo.deviceLon), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createTextVNode(" 纬度 "),
                                      _createVNode(_component_el_tag, {
                                        size: "small",
                                        type: "warning"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.xqjzInfo.deviceLat), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_button, {
                                    type: "primary",
                                    icon: "el-icon-map-location",
                                    circle: "",
                                    size: "mini",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickXqjzPos({ lng: _ctx.xqjzInfo.deviceLon, lat: _ctx.xqjzInfo.deviceLat })))
                                  })
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createElementBlock("div", _hoisted_3, "暂无经纬度信息"))
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, null, {
                      label: _withCtx(() => [
                        _createTextVNode(" 地址 ")
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.xqjzInfo.deviceAddress), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "原木出库",
                  class: "description-item",
                  direction: "vertical",
                  column: 4,
                  border: ""
                }, {
                  extra: _withCtx(() => [
                    (_ctx.woodOutOfInfo.woInfo)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "primary",
                          size: "small",
                          onClick: _ctx.onExportWarehouseOut
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("导出")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.woodOutOfInfo.woInfo)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.woodOutOfInfo.xwrdList, (xwrd, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: xwrd.id
                            }, [
                              _createVNode(_component_el_descriptions_item, {
                                label: "产品名称",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(xwrd.goodsName), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "出库数量",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(xwrd.xwrdNum + '方'), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "出库时间",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.woodOutOfInfo.woInfo.sendDate)), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"])
                            ], 64))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_empty, {
                                  description: "暂无原木出库信息",
                                  "image-size": 60,
                                  style: {"height":"125px"}
                                })
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "成品入库",
                  class: "description-item",
                  direction: "vertical",
                  column: 3,
                  border: ""
                }, {
                  extra: _withCtx(() => [
                    (_ctx.productInfoInfo.wiInfo)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "primary",
                          size: "small",
                          onClick: _ctx.onExportProductIn
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("导出")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.productInfoInfo.xwrdList && _ctx.productInfoInfo.xwrdList.length)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.productInfoInfo.xwrdList, (xwrd, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: xwrd.id
                            }, [
                              _createVNode(_component_el_descriptions_item, {
                                label: "产品名称",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(xwrd.goodsName), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "入库数量",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(xwrd.xwrdNum + xwrd.xwrdUnit), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "入库时间",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.productInfoInfo.wiInfo.xwrTime)), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"])
                            ], 64))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_empty, {
                                  description: "暂无成品入库信息",
                                  "image-size": 60,
                                  style: {"height":"125px"}
                                })
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "成品出库",
                  class: "description-item",
                  direction: "vertical",
                  column: 3,
                  border: ""
                }, {
                  extra: _withCtx(() => [
                    (_ctx.productOutofInfo.woInfo)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "primary",
                          size: "small",
                          onClick: _ctx.onExportProductOut
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("导出")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.productOutofInfo.xwrdList && _ctx.productOutofInfo.xwrdList.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            (_ctx.productOutofInfo.woInfo)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.productOutofInfo.xwrdList, (xwrd, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, {
                                    key: xwrd.id
                                  }, [
                                    _createVNode(_component_el_descriptions_item, {
                                      label: "产品名称",
                                      "label-class-name": index == 0? '': 'hidden'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(xwrd.goodsName), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label-class-name"]),
                                    _createVNode(_component_el_descriptions_item, {
                                      label: "出库数量",
                                      "label-class-name": index == 0? '': 'hidden'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(xwrd.xwrdNum + '方'), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label-class-name"]),
                                    _createVNode(_component_el_descriptions_item, {
                                      label: "出库时间",
                                      "label-class-name": index == 0? '': 'hidden'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.productOutofInfo.woInfo.sendDate)), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label-class-name"])
                                  ], 64))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_empty, {
                                  description: "暂无成品出库信息",
                                  "image-size": 60,
                                  style: {"height":"125px"}
                                })
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "收款信息",
                  class: "description-item",
                  column: 3,
                  border: ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.xqjProceedsInfo.paytime)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_el_descriptions_item, null, {
                            label: _withCtx(() => [
                              _createTextVNode(" 收款时间 ")
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.formatTime(_ctx.xqjProceedsInfo.paytime)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_descriptions_item, null, {
                            label: _withCtx(() => [
                              _createTextVNode(" 收款金额(元) ")
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.xqjProceedsInfo.entryamt), 1)
                            ]),
                            _: 1
                          })
                        ], 64))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_empty, {
                                description: "暂无收款信息",
                                "image-size": 60,
                                style: {"height":"125px"}
                              })
                            ]),
                            _: 1
                          })
                        ]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "支付信息",
                  class: "description-item",
                  direction: "vertical",
                  column: 4,
                  border: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.xqjFundsPayInfo[0])
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.xqjFundsPayInfo, (payInfo, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              _createVNode(_component_el_descriptions_item, {
                                label: "支付款项",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(payInfo.ledgerType), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "收款人",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(payInfo.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "收款时间",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatTime(payInfo.payTime)), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"]),
                              _createVNode(_component_el_descriptions_item, {
                                label: "收款金额(元)",
                                "label-class-name": index == 0? '': 'hidden'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(payInfo.amount), 1)
                                ]),
                                _: 2
                              }, 1032, ["label-class-name"])
                            ], 64))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_empty, {
                                  description: "暂无支付信息",
                                  "image-size": 60,
                                  style: {"height":"125px"}
                                })
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_timeline_item, {
              timestamp: "",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions, {
                  title: "开票信息",
                  class: "description-item",
                  column: 3,
                  border: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.invoiceInfo.invoiceCode)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_el_descriptions_item, null, {
                              label: _withCtx(() => [
                                _createTextVNode(" 发票号 ")
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_ctx.invoiceInfo.invoiceCode), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_descriptions_item, null, {
                              label: _withCtx(() => [
                                _createTextVNode(" 开票时间 ")
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.invoiceInfo.invoiceDate)), 1)
                              ]),
                              _: 1
                            })
                          ], 64))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_el_descriptions_item, { "label-class-name": "hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_empty, {
                                  description: "暂无开票信息",
                                  "image-size": 60,
                                  style: {"height":"125px"}
                                })
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_XqjzPosMap, { ref: "xqjzPosMapRef" }, null, 512),
      _createVNode(_component_ExportTable, {
        ref: "exportTableRef",
        fileName: _ctx.fileName,
        excelFormat: false,
        tableLayout: _ctx.exportLayout,
        baseStyle: {
        fontFamily: 'Microsoft Yahei'
      }
      }, null, 8, ["fileName", "tableLayout"])
    ]),
    _: 1
  }))
}