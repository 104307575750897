
import { ElRadio, ElRadioButton } from 'element-plus'
import { getCurrentInstance, h, withScopeId, PropType, defineComponent, computed } from 'vue'
import { authFilter, callIfFunction, showableFilter } from './utils'
import { 
  Inputs, 
  InputConfig, 
  InputInputConfig, 
  PlaceholderConfig,
  SelectInputConfig, 
  DateInputConfig, 
  AutocompleteInputConfig, 
  SwitchInputConfig, 
  RadioInputConfig,
  GroupInputConfig,
  RenderFunc,
  CascaderConfig
} from '@/types/BasicComponent'

export default defineComponent({
  props: {
    inputs: {
      type: Array as PropType<Inputs>,
      default: []
    },
    render: {
      // @ts-ignore
      type: Function as PropType<RenderFunc<[JSX.Element, InputConfig]>>,
    }
  },
  setup(props) {
    const placeholderConfig = (inputConfig: InputConfig & PlaceholderConfig, prefix: string) => {
      if (!(inputConfig as InputInputConfig).model?.value && callIfFunction((inputConfig as InputInputConfig).disabled, false)) {
        return ''
      }
      if (inputConfig.placeholder) return inputConfig.placeholder

      if (typeof inputConfig.label === 'function') return <span>{prefix}{inputConfig.label()}</span>
      if (typeof inputConfig.label === 'string') return prefix + inputConfig.label
      return ''
    }

    const validatedInputs = computed(() => {
      return authFilter(showableFilter(props.inputs))
    })

    const inputRender = (inputConfig: InputInputConfig) => (
      <el-input
        placeholder={placeholderConfig(inputConfig, '请输入')}
        clearable={callIfFunction(inputConfig.clearable, false)}
        v-model={inputConfig.model}
        type={inputConfig.type}
        disabled={callIfFunction(inputConfig.disabled, false)}
        size="small"
        prefix-icon={callIfFunction(inputConfig.prefixIcon, '')}
        suffix-icon={callIfFunction(inputConfig.suffixIcon, '')}
        v-slots={{
          prefix: inputConfig.prefixRender,
          suffix: inputConfig.suffixRender,
        }}
        style={{
          width: inputConfig.width || '200px'
        }}

        onClear={inputConfig.onClear}
        onBlur={inputConfig.onBlur}
        onFocus={inputConfig.onFocus}
        onInput={inputConfig.onInput}
        onChange={inputConfig.onChange}
      ></el-input>
    )
    const selectRender = (inputConfig: SelectInputConfig) => (
      <el-select
        placeholder={placeholderConfig(inputConfig, '请选择')}
        clearable={inputConfig.clearable === false ? false: true}
        multiple={inputConfig.multiple || false}
        remote={inputConfig.remote || false}
        filterable={inputConfig.filterable || false}
        reserve-keyword={inputConfig.reserveKeyword || false}
        remote-method={inputConfig.remoteMethod? inputConfig.remoteMethod : () => 1}
        onChange={inputConfig.onChange? inputConfig.onChange : () => 1}
        loading={inputConfig.loading || false}
        v-model={inputConfig.model}
        size="small"
        disabled={callIfFunction(inputConfig.disabled, false)}
        style={{
          width: inputConfig.width || '200px'
        }}
      >
        {inputConfig.options && inputConfig.options.map(option => (
          <el-option
            key={option.key != null? option.key: option.value}
            label={option.label}
            value={option.value}
          ></el-option>
        ))}
      </el-select>
    )
    const cascaderRender = (inputConfig: CascaderConfig) => (
      <el-cascader
        v-model={inputConfig.model}
        props={inputConfig.props}
        size={inputConfig.size || 'small'}
        placeholder={inputConfig.placeholder}
        disabled={callIfFunction(inputConfig.disabled, false)}
        clearable={callIfFunction(inputConfig.clearable, false)}
        showAllLevels={callIfFunction(inputConfig.showAllLevels, true)}
        collapseTags={callIfFunction(inputConfig.collapseTags, false)}
        separator={inputConfig.separator || '/'}
        filterable={callIfFunction(inputConfig.filterable, false)}
        filterMethod={inputConfig.filterMethod || (() => 0)}
        debounce={inputConfig.debounce || 300}
        beforeFilter={inputConfig.beforeFilter || undefined}
        popperClass={inputConfig.popperClass}
        popperAppendToBody={callIfFunction(inputConfig.popperAppendToBody, true)}
        onChange={inputConfig.onChange}
        onExpandChange={inputConfig.onExpandChange}
        onBlur={inputConfig.onBlur}
        onFocus={inputConfig.onFocus}
        onVisibleChange={inputConfig.onVisibleChange}
        onRemoveRag={inputConfig.onVisibleChange}
      ></el-cascader>
    )
    const dateRedner = (inputConfig: DateInputConfig) => (
      <el-date-picker
        type={inputConfig.type}
        placeholder={placeholderConfig(inputConfig, '请选择')}
        range-separator={inputConfig.rangeSeparator || "至"}
        start-placeholder={inputConfig.startPlaceholder || "开始日期"}
        end-placeholder={inputConfig.endPlaceholder || "结束日期"}
        clearable={inputConfig.clearable === false? false : true}
        v-model={inputConfig.model}
        size="small"
        disabledDate={inputConfig.disabledDate}
        disabled={callIfFunction(inputConfig.disabled, false)}
        style={{
          width: inputConfig.width || '200px'
        }}
      ></el-date-picker>
    )
    const autocompleteRender = (inputConfig: AutocompleteInputConfig) => (
      <el-autocomplete
        v-model={inputConfig.model}
        placeholder={placeholderConfig(inputConfig, '请选择')}
        fetch-suggestions={inputConfig.fetchSuggections}
        clearable={inputConfig.clearable === false? false : true}
        disabled={inputConfig.disabled || false}
        onSelect={inputConfig.onSelect}
        onBlur={inputConfig.onBlur}
        onClear={inputConfig.onClear}
        size="small"
        style={{
          width: inputConfig.width || '200px'
        }}
      ></el-autocomplete>
    )
    const switchRender = (inputConfig: SwitchInputConfig) => (
      <el-switch
        v-model={inputConfig.model}
        disabled={inputConfig.disabled || false}
        active-text={inputConfig.activeText}
        inactive-text={inputConfig.inactiveText}
        active-value={inputConfig.activeValue}
        inactive-value={inputConfig.inactiveValue}
      ></el-switch>
    )
    const radioRender = (inputConfig: RadioInputConfig) => (
      <el-radio-group 
        v-model={inputConfig.model}
        disabled={inputConfig.disabled || false}
        size={inputConfig.size || 'small'}
      >
        {inputConfig.radios && inputConfig.radios.map(radio => (
          h(
            inputConfig.radioType === 'button'? ElRadioButton :ElRadio,
            {label: radio.value},
            {default: () => radio.label}
          )
        ))}
      </el-radio-group>
    )

    const renderCondition = (config: InputConfig) => {
      const { type } = config
      let renderResult = <span></span>
      if (['input', 'textarea'].includes(type)) renderResult = inputRender(config as InputInputConfig)
      else if (type === 'select') renderResult = selectRender(config as SelectInputConfig)
      else if (type === 'cascader') renderResult = cascaderRender(config as CascaderConfig)
      else if (['year', 'month', 'date', 'dates', 'week', 'datetime', 'datetimerange', 'daterange', 'monthrange'].includes(type)) renderResult = dateRedner(config as DateInputConfig)
      else if (type === 'autocomplete') renderResult = autocompleteRender(config as AutocompleteInputConfig)
      else if (type === 'switch') renderResult = switchRender(config as SwitchInputConfig)
      else if (type === 'radio') renderResult = radioRender(config as RadioInputConfig)
      else if (type === 'group') {
        config = config as GroupInputConfig
        const groupRenderResult = []
        const inputs = config.inputs
        for (let i in inputs) {
          if (Number(i) !== inputs.length - 1) {
            groupRenderResult.push(
              <span>
                {renderCondition(inputs[i])}
                {typeof config.divider === 'function'
                  ? config.divider()
                  : <span style={{marginLeft: '8px'}}>{config.divider}</span>
                }
              </span>
            )
          } else {
            groupRenderResult.push(<>{renderCondition(inputs[i])}</>)
          }
        }
        // @ts-ignore
        renderResult = groupRenderResult as unknown as JSX.Element
      }
      // @ts-ignore
      const renderFunc: RenderFunc<[JSX.Element]> = (config as any).render
      if (renderFunc) return renderFunc(renderResult)
      return renderResult
    }

    // 给元素加上scopeid，这样render函数渲染出来的元素能正确地被scoped的css影响
    const instance = getCurrentInstance()
    const withId = withScopeId(instance?.vnode.scopeId as string)

    return withId(() => {
      return (
        <>
          {validatedInputs.value.map(inputConfig => (
            props.render
              ? props.render(renderCondition(inputConfig) as any, inputConfig)
              : renderCondition(inputConfig)
          ))}
        </>
      )
    })
  }
})
