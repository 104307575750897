import { computed, reactive } from "vue"
import { useStore } from "vuex"
import { UserRole } from '@/types/Role'
import { RootState } from '@/types/Store'

export default () => {
  const store = useStore<RootState>()
  const userRoles = computed<UserRole[]>(() => store.state.user.roles)
  const userInfo = computed(() => store.getters.userInfo)
  const hasRole = (role: UserRole) => {
    return userRoles.value.indexOf(role) !== -1
  }
  const hasAllRole = (...roles: UserRole[]) => {
    return roles.every(role => userRoles.value.indexOf(role) !== -1)
  }
  const hasOneOfRole = (...roles: UserRole[]) => {
    return roles.some(role => userRoles.value.indexOf(role) !== -1)
  }
  const hasOnlyOneRole = (role: UserRole) => {
    let c = 0
    userRoles.value.forEach(a => {
      if (a === role) c ++
    })
    return c === 1
  }

  return reactive({
    userRoles,
    userInfo,
    hasRole,
    hasAllRole,
    hasOneOfRole,
    hasOnlyOneRole
  })
}