
import { defineComponent, onActivated, reactive, ref } from "vue";
import BasicPage from '@/components/BasicPageLayout/BasicPage.vue'
import XqjzPosMap from '@/views/finance/XqjzPosMap.vue'
import ExportTable from '@/components/ExportExcel/index'
import { GEOPosition } from "@/types/Common";
import { getWarehouseInExportLayout, getWarehouseOutExportLayout } from '@/views/finance/exportLayout'
import { TableLayout } from "@/types/BasicComponent";
import { getWoodIntoInfo, getXqjDeviceInfo, getWoodOutOfInfo, getProductIntoInfo, getPowerConsumeInfo, getXqjProceedsInfo, getXqjFundsPayInfo, getInvoiceInfo, getProductOutofInfo } from '@/api/production'
import { getOrderList } from '@/api/order'
import { copyObject, formatTime, formatDate } from "@/utils/common";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    BasicPage,
    XqjzPosMap,
    ExportTable
  },
  setup(props) {

    const route = useRoute()
    const orderId = ref()

    onActivated(() => {
      const parsedId = parseInt(route.query.orderId as string)
      if (parsedId > 0) {
        orderId.value = parsedId
        queryData()
      }
    })

    const orderInfo = reactive<AnyObject>({})
    const woodIntoInfo = reactive<AnyObject>({})
    const xqjzInfo = reactive<AnyObject>({})
    const woodOutOfInfo = reactive<AnyObject>({})
    const productInfoInfo = reactive<AnyObject>({})
    const productOutofInfo = reactive<AnyObject>({})
    const powerConsumeInfo = reactive<AnyObject>({})
    const xqjProceedsInfo = reactive<AnyObject>({})
    const xqjFundsPayInfo = reactive<AnyObject>({})
    const invoiceInfo = reactive<AnyObject>({})

    const queryData = () => {
      Promise.all([getOrderList({ orderId: orderId.value, pageNo: 1, pageSize: 1 }), getWoodIntoInfo(orderId.value), getXqjDeviceInfo(orderId.value),
          getWoodOutOfInfo(orderId.value), getProductIntoInfo(orderId.value), getProductOutofInfo(orderId.value), 
          getPowerConsumeInfo(orderId.value), getXqjProceedsInfo(orderId.value), getXqjFundsPayInfo(orderId.value), 
          getInvoiceInfo(orderId.value)])
        .then(([oi, wii, xqjz, wooi, pii, pooi, pci, xpi, xfpi, ii]) => {
          copyObject(orderInfo, oi.data.data.records[0])
          copyObject(woodIntoInfo, wii.data.data)
          copyObject(xqjzInfo, xqjz.data.data)
          copyObject(woodOutOfInfo, wooi.data.data)
          copyObject(productInfoInfo, pii.data.data)
          copyObject(productOutofInfo, pooi.data.data)
          copyObject(powerConsumeInfo, pci.data.data)
          copyObject(xqjProceedsInfo, xpi.data.data)
          copyObject(xqjFundsPayInfo, xfpi.data.data)
          copyObject(invoiceInfo, ii.data.data)
        })
    }

    const xqjzPosMapRef = ref()
    const onClickXqjzPos = (pos: GEOPosition) => {
      xqjzPosMapRef.value.show(pos)
    }

    const exportLayout = ref<TableLayout>({ thead: [], tbody: [], tfoot: [] })
    const exportTableRef = ref()
    const fileName = ref('')

    const onExportWarehouseIn = () => {
      fileName.value = `订单${orderInfo.orderCode}原木入库单`
      woodIntoInfo.wiInfo.corpName = woodOutOfInfo.woInfo.corpName
      exportLayout.value = getWarehouseInExportLayout(orderInfo, woodIntoInfo.wiInfo, woodIntoInfo.xwrdList)
      exportTableRef.value.export()
    }
    const onExportWarehouseOut = () => {
      fileName.value = `订单${orderInfo.orderCode}原木出库单`
      exportLayout.value = getWarehouseOutExportLayout(orderInfo, woodOutOfInfo.woInfo, woodOutOfInfo.xwrdList)
      exportTableRef.value.export()
    }

    const onExportProductIn = () => {
      fileName.value = `订单${orderInfo.orderCode}成品入库单`
      productInfoInfo.wiInfo.corpName = woodOutOfInfo.woInfo.corpName
      exportLayout.value = getWarehouseInExportLayout(orderInfo, productInfoInfo.wiInfo, productInfoInfo.xwrdList)
      exportTableRef.value.export()
    }
    const onExportProductOut = () => {
      fileName.value = `订单${orderInfo.orderCode}成品出库单`
      exportLayout.value = getWarehouseOutExportLayout(orderInfo, productOutofInfo.woInfo, productOutofInfo.xwrdList)
      exportTableRef.value.export()
    }

    return {
      orderInfo,
      woodIntoInfo,
      xqjzInfo,
      woodOutOfInfo,
      productInfoInfo,
      productOutofInfo,
      powerConsumeInfo,
      xqjProceedsInfo,
      xqjFundsPayInfo,
      invoiceInfo,

      exportTableRef,
      xqjzPosMapRef,
      exportLayout,
      fileName,
      
      onClickXqjzPos,
      onExportWarehouseIn,
      onExportWarehouseOut,
      onExportProductIn,
      onExportProductOut,

      formatTime,
      formatDate,
    }
  }
})
