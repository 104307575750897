import useRole from '@/utils/composables/useRole'
import { AuthConfig, ButtonConfig, DropdownConfig, Showable } from '@/types/BasicComponent'
import dragable from '@/utils/dragable'

export const callIfFunction = (value: any, defaultVal: any, ...args: any[]) => {
  if (typeof value === 'function')
    return value(...args)
  else if (value != null) return value
  else return defaultVal
}

export const authFilter = <T extends AuthConfig>(configArray: T[]) => {
  const { hasOneOfRole } = useRole()
  return configArray.filter(
    config => !config.auth || hasOneOfRole(...config.auth)
  )
}

export const showableFilter = <T extends Showable | Partial<Showable>>(configArray: T[]) => {
  
  return configArray.filter(config => callIfFunction(config.show, true))
}

export const enableDrag = (dragableDom: HTMLElement | string, dragableArea: HTMLElement | string) => {
  if (typeof dragableDom === 'string')
    dragableDom = document.querySelector(dragableDom) as HTMLElement
  if (typeof dragableArea === 'string')
    dragableArea = dragableDom.querySelector(dragableArea) as HTMLElement
  if (dragableDom && dragableArea)
    dragable(dragableDom, dragableArea)
}

export const dropdownLayout = (buttonConfig: DropdownConfig, scope = undefined) => {
  return (
    <el-dropdown
      size={callIfFunction(buttonConfig.size, 'small', scope)}
      type={callIfFunction(buttonConfig.type, 'default', scope)}
      maxHeight={callIfFunction(buttonConfig.maxHeight, undefined, scope)}
      splitButton={callIfFunction(buttonConfig.splitButton, false, scope)}
      disabled={callIfFunction(buttonConfig.disabled, false, scope)}
      placement={callIfFunction(buttonConfig.placement, 'bottom', scope)}
      trigger={callIfFunction(buttonConfig.trigger, 'hover', scope)}
      hideOnClick={callIfFunction(buttonConfig.hideOnClick, true, scope)}
      showTimeout={callIfFunction(buttonConfig.showTimeout, 250, scope)}
      hideTimeout={callIfFunction(buttonConfig.hideTimeout, 150, scope)}
      tabindex={callIfFunction(buttonConfig.size, 0, scope)}
      onClick={buttonConfig.onClick}
      onCommand={buttonConfig.onCommand}
      onVisibleChange={buttonConfig.onVisibleChange}
      v-slots={{
        default: () => {
          if (typeof buttonConfig.render == 'function') {
            return buttonConfig.render()
          } else if (buttonConfig.button) {
            buttonConfig.button.render = () => {
              return (
                <div>
                  {callIfFunction(buttonConfig.button?.label, '')}
                  <i class="el-icon-arrow-down" style={{ transform: 'translateX(6px)' }}></i>
                </div>
              )
            }
            return renderButton(buttonConfig.button)
          }
        },
        dropdown: () => {
          if (!buttonConfig.menu) return undefined
          return (
            <el-dropdown-menu
              v-slots={{
                default: () => {
                  if (!buttonConfig.menu?.items) return undefined
                  return (
                    buttonConfig.menu.items.map(item => {
                      return (
                        <el-dropdown-item
                          v-slots={{ default: () => item.render?.() || item.label }}
                          command={callIfFunction(item.command, '', scope)}
                          disabled={callIfFunction(item.disabled, false, scope)}
                          divided={callIfFunction(item.divided, false, scope)}
                          icon={callIfFunction(item.icon, undefined, scope)}
                        >
                        </el-dropdown-item>
                      )
                    })
                  )
                }
              }}
            >
            </el-dropdown-menu>
          )
        }
      }}
    >
    </el-dropdown>
  )
}

export const renderButton = (buttonConfig: ButtonConfig | DropdownConfig, scope: any = undefined) => {
  if (buttonConfig.show && !callIfFunction(buttonConfig.show, false, scope)) return 
  if (buttonConfig.nativeType != 'dropdown') {
    return (
      <el-button
        type={callIfFunction(buttonConfig.type, 'primary', scope)}
        size="small"
        disabled={callIfFunction(buttonConfig.disabled, false, scope)}
        plain={callIfFunction(buttonConfig.plain, false, scope)}
        onClick={() => buttonConfig.onClick?.(scope)}
        icon={buttonConfig.icon}
        v-slots={
          buttonConfig.label || buttonConfig.render ? { default: () => {
            if (buttonConfig.render) return buttonConfig.render()
            return callIfFunction(buttonConfig.label, '', scope)
          } } : null
        }
      ></el-button>
    )
  } else {
    return dropdownLayout(buttonConfig)
  }
}